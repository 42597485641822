import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Form } from 'react-formio';

import './Contact.css'

export class Contact extends Component {
    displayName = Contact.name
  
    render() {
        return (
            <Container fluid>
                <Row className="justify-content-center">
                    <Col xs={12}>
                        <h2 className="title">CONTACT ME</h2>
                    </Col>
                </Row>
                <Row><Col><div className="divider"></div></Col></Row>
                <Row className="justify-content-center">
                    <Col xs={11} md={6} sm={8} lg={5}>
                        <p className="about-me-text">I'd love to hear from you! Send me a message here or reach me on instagram or facebook.</p>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Form src="https://rbrruahrxndyljt.form.io/florannacontactform" />
                </Row>
            </Container>
        );
    }
}
