import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import Header from './Header/Header';
import './Layout.css';

export class Layout extends Component {
  displayName = Layout.name

  render() {
    return (
      <Container fluid>
        <Row className="justify-content-center">
          <Col>
            <Row className="justify-content-center">
              <Header />
            </Row>
            <Row>
              <div className="main-content">
                {this.props.children}
              </div>
            </Row>
            <Row className="justify-content-center">
              <div className="footer-col">
                <Container fluid>
                  <Row>
                    <Col>
                      <hr />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={4}>
                      <div><Link to="/">Home</Link></div>
                      <div><Link to="/about">About Me</Link></div>
                      <div><Link to="/contact">Contact</Link></div>
                    </Col>
                    <Col sm={4}>
                      <div><a href="https://www.instagram.com/floranna_fibre_arts/">instagram</a></div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col className="footer-copyright">
            <footer className="footer mt-auto py-3">
              <Container>
                <Row className="justify-content-center">
                  <Col className="footer-text">
                    <span className="text-muted">© 2021 by Floranna</span>
                  </Col>
                </Row>
              </Container>
            </footer>
          </Col>
        </Row>
      </Container>
    );
  }
}
