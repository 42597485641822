import React from 'react'
import Nav from 'react-bootstrap/Nav'
import './Header.css'
import { NavLink, Link } from 'react-router-dom';

const Header = (props) => {
  return (
    <header className="header-content">
      <div className="header-image">
        <Link to="/">
          <img src="/images/floranna_wide.png" alt="" className="img-fluid" />
        </Link>
      </div>
      <Nav justify variant="pills">
        <Nav.Item>
          <NavLink to="/" exact className="nav-link" activeClassName="active">
            Home
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/about" exact className="nav-link" activeClassName="active">
            About Me
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/contact" exact className="nav-link" activeClassName="active">
            Contact
          </NavLink>
        </Nav.Item>
      </Nav>
    </header>
  )
}

export default Header
