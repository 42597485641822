import React from 'react'
import { Link } from 'react-router-dom'
import './ProductBox.css'

const ProductBox = (props) => {
    return (
        <div className="product-box">
            <section className="product-box-container">
                <Link className="product-box-container-link" to={"products/" + props.id}>
                    <div className="visual-section-wrapper">
                        <img src={props.img} alt="" className="img-fluid" />
                    </div>
                    <div className="content-elements">
                            <p className="inner-element product-name" id="product-name">{props.name}</p>
                            <hr className="product-box-separator" />
                            <div className="inner-element prices">
                                <span className="price">
                                    <span className="sr-only" id="sr-price">Price</span>
                                    <span id="price">{props.price}</span>
                                </span>
                            </div>
                    </div>
                </Link>
            </section>
        </div>
    )
}


export default ProductBox
