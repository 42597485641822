import { useQuery } from "react-query";

const sortProduct = function (a, b) {
    if (a.priority < b.priority) return -1;
    if (a.priority > b.priority) return 1;
    return new Date(b.updatedAt) - new Date(a.updatedAt);
}

const products = {
    unknown: {
        name: "Unknown",
        price: 0,
        sku: "Unknown",
        description: "A really nice something. You should buy it!",
        image: {
            url: null
        },
        thumbnail: "/images/seed_stitch_button_scarf_thumbnail.jpg"
    },
    getPatterns: (productList) => productList.filter(p => p.categoryName === "Patterns").sort(sortProduct),
    getHomeProducts: (productList) => productList.filter(p => p.categoryName === "Home").sort(sortProduct),
    getHatsAndMittens: (productList) => productList.filter(p => p.categoryName === "Hats" || p.categoryName === "Mittens").sort(sortProduct),
    getSlippers: (productList) => productList.filter(p => p.categoryName === "Slippers").sort(sortProduct),
    getScarves: (productList) => productList.filter(p => p.categoryName === "Scarves").sort(sortProduct)
};

const formatPrice = price => price === 0
    ? "Free"
    : "C$" + (price ? price.toFixed(2) : "-.--");
const getDefaultVariation = p => (p.variations && p.variations.length > 0)
  ? p.variations.find(v => v.name === "Regular") || p.variations[0]
  : null;
const getProduct = (p, v) => ({
    id: p.id,
    name: p.name,
    url: p.image.url,
    description: p.description,
    sku: v ? v.sku : null,
    price: v ? v.price : null,
    formattedPrice: formatPrice(v ? v.price : null),
    priority: v ? v.priority : null,
    link: v ? v.link : null,
    linkText: v ? v.linkText : null
  });
export const getProductWithDefaultVariation = p => getProduct(p, getDefaultVariation(p));

export function useProducts() {
    return useQuery("products", async () => {
        const response = await fetch(`${process.env.REACT_APP_API}/products`);
        if (!response.ok) {
            console.error(response);
            throw new Error('Network response was not ok')
        }
        return response.json();
    }, {        
        placeholderData: [],
        staleTime: 300000,
    });
}

export function useProduct(productId) {
    return useQuery(["product", productId], async () => {
        const response = await fetch(`${process.env.REACT_APP_API}/products/${productId}`);
        if (!response.ok) {
            console.error(response);
            throw new Error('Network response was not ok')
        }
        return response.json();
    }, {
        enabled: !!productId,
        staleTime: 300000,
    });
}

export default products;