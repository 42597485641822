import React from "react";
import { useQueryClient } from "react-query";
import Carousel from 'react-bootstrap/Carousel'
import ProductBox from './ProductBox/ProductBox'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Products from '../data/Products'
import { useProducts, getProductWithDefaultVariation } from '../data/Products'
import './Home.css'

const Home = () => {

  const { 
    status, // 'idle' | 'loading' | 'error' | 'success'
    data, 
    //error, // TError
    isFetching // boolean
  } = useProducts();
  const queryClient = useQueryClient();

  const products = !isFetching && status === 'success' ? data : [];

  products.forEach(p => {
    queryClient.setQueryData(['product', p.id], p);
  });

  const chunk = (arr, size) =>
      Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
        arr.slice(i * size, i * size + size)
      );
  const buildProduct = p => p
    ? <ProductBox key={p.id}
        name={p.name}
        img={p.url}
        id={p.id}
        price={p.formattedPrice} />
    : null;
  const buildProductWithDefaultVariation = p => buildProduct(getProductWithDefaultVariation(p));  
  const allPatterns = Products.getPatterns(products).map(buildProductWithDefaultVariation);
  const allHomeProducts = Products.getHomeProducts(products).map(buildProductWithDefaultVariation);
  const allHatsAndMittens = Products.getHatsAndMittens(products).map(buildProductWithDefaultVariation);
  const allSlippers = Products.getSlippers(products).map(buildProductWithDefaultVariation);
  const allScarves = Products.getScarves(products).map(buildProductWithDefaultVariation);

  const numVisibleProducts = 3;
  const buildCarouselItem = (name, productGroup) =>
    (<Carousel.Item key={name}>
      <div className="products">
        {productGroup}
      </div>
    </Carousel.Item>);
  const buildCarousel = items =>
    <Carousel interval={null} indicators={false} controls={items.length > 1}>
      {items}
    </Carousel>;
  const carouselPatterns = buildCarousel(chunk(allPatterns, numVisibleProducts).map((g, ix) => buildCarouselItem(`patterns${ix}`, g)));
  const carouselHomeProducts = buildCarousel(chunk(allHomeProducts, numVisibleProducts).map((g, ix) => buildCarouselItem(`home${ix}`, g)));
  const carouselHatsAndMittens = buildCarousel(chunk(allHatsAndMittens, numVisibleProducts).map((g, ix) => buildCarouselItem(`hatsAndMitts${ix}`, g)));
  const carouselSlippers = buildCarousel(chunk(allSlippers, numVisibleProducts).map((g, ix) => buildCarouselItem(`slippers${ix}`, g)));
  const carouselScarves = buildCarousel(chunk(allScarves, numVisibleProducts).map((g, ix) => buildCarouselItem(`scarves${ix}`, g)));

  return (
    <Container fluid>
      <Row className="carousel-row">
        <Col>
          <Carousel interval={6000}>
            <Carousel.Item>
              <img width={980} height={523} alt="" src="images/carousel_baskets.jpg" className="d-block w-100" />
              <Carousel.Caption>
                <h3 style={{ top: '-350px', right: '-300px' }}>HANDMADE HOME <br /> ACCESSORIES</h3>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img width={980} height={523} alt="" src="images/carousel_cowel_grey.jpg" className="d-block w-100" />
              <Carousel.Caption>
                <h3 style={{ bottom: '20px', right: '-320px' }}>COZY COWLS</h3>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </Col>
      </Row>
      <Row>
        <Col className="section">
          <h3>Patterns</h3>
          <div className="carousel-products">
            {carouselPatterns}
          </div>
          <div className="mobile-products">
            <div className="products">
              {allPatterns}
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="section">
          <h3>Home Accessories</h3>
          <div className="carousel-products">
            {carouselHomeProducts}
          </div>
          <div className="mobile-products">
            <div className="products">
              {allHomeProducts}
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="section">
          <h3>Hats &amp; Mittens</h3>
          <div className="carousel-products">
            {carouselHatsAndMittens}
          </div>
          <div className="mobile-products">
            <div className="products">
              {allHatsAndMittens}
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="section">
          <h3>Slippers</h3>
          <div className="carousel-products">
            {carouselSlippers}
          </div>
          <div className="mobile-products">
            <div className="products">
              {allSlippers}
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="section">
          <h3>Scarves</h3>
          <div className="carousel-products">
            {carouselScarves}
          </div>
          <div className="mobile-products">
            <div className="products">
              {allScarves}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
export default Home;
