import { Route } from 'react-router-dom';
import { Switch } from 'react-router-dom';
import { Layout } from './components/Layout';
import Home from './components/Home';
import { About } from './components/About';
import { Contact } from './components/Contact';
import Product from './components/Product';
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./AppInsights";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import './App.scss';

const queryClient = new QueryClient();

function App() {
  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <QueryClientProvider client={queryClient}>
          <Layout>
            <Switch>
              <Route exact path='/' component={Home} />
              <Route exact path='/about' component={About} />
              <Route exact path='/contact' component={Contact} />
              <Route exact path='/products/:id' component={Product} />
            </Switch>
          </Layout>
          <ReactQueryDevtools initialIsOpen />
      </QueryClientProvider>
    </AppInsightsContext.Provider>
  );
}

export default App;
