import React, { useState } from "react";
import { useParams, Link } from "react-router-dom";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Col, Container, Row } from "react-bootstrap";
import { Form } from "react-formio";
import Products, { useProduct, getProductWithDefaultVariation } from "../data/Products";
import "./Product.css";

const Product = () => {
  const { id } = useParams();
  const { 
    status, // 'idle' | 'loading' | 'error' | 'success'
    data, 
    //error, // TError
    isFetching // boolean
  } = useProduct(id);
  const [showRequestForm, setShowRequestForm] = useState(false);
  const [submissionData, setSubmissionData] = useState({
    data: { product: ""}
  });  

  const product = status === 'success' && !isFetching ? getProductWithDefaultVariation(data) : Products.unknown;
  
  let productRequestId = p => p.sku ? p.sku : p.name;

  submissionData.data.product = product ? "Product Request: " + productRequestId(product) : null;

  let update = (updatedSubmission) => {
    if (updatedSubmission.changed) {
      setSubmissionData({ data: updatedSubmission.data });
    }
  };
  let requestProductForm = showRequestForm ? (
    <div className="request-product-form">
      <Form
        src="https://rbrruahrxndyljt.form.io/requestproduct"
        submission={submissionData}
        onChange={update}
      />
    </div>
  ) : null;

  let productBreadcrumb = product 
    ? <Breadcrumb.Item active>{product.name}</Breadcrumb.Item>
    : null;

  let buildProductImage = product => product && product.url
    ? <Col xs={12} sm={6} className="product-image">
        <img className="d-block w-100" src={product.url} alt={product.name} />
      </Col>
    : null;

  let skuElement = product && product.sku 
    ? <p>SKU: {product.sku}</p> 
    : null;

  let actionButton = product && product.link
    ? (<a href={product.link} className="btn btn-info btn-md" target="_blank" rel="noopener noreferrer">
        {product.linkText ? product.linkText : "Purchase"}
      </a>)
    : (<button className="btn btn-info btn-md" onClick={() => setShowRequestForm(!showRequestForm)}>
        {showRequestForm ? "Hide" : "Request"}
      </button>);  

  let buildProductDetails = product => product
    ? <Col className="product-details">
        <h1>{product.name}</h1>
        {skuElement}
        <p>{product.formattedPrice}</p>
        <p>{product.description}</p>
        <div>
          {actionButton}
        </div>
        {requestProductForm}
      </Col>
    : null;

  return (
    <Container fluid className="product-content">
      <Row>
        <Breadcrumb className="breadcrumb-content">
          <Breadcrumb.Item linkAs={Link} linkProps={{to: "/"}}>Home</Breadcrumb.Item>
          {productBreadcrumb}
        </Breadcrumb>
      </Row>
      <Row>
        {buildProductImage(product)}
        {buildProductDetails(product)}
      </Row>
    </Container>
  );
};
export default Product;
