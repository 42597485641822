import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import './About.css'

export class About extends Component {
    displayName = About.name
  
    render() {
        return (
            <Container fluid className="about-container">
                <Row className="spacer">&nbsp;</Row>
                <Row>
                    <Col className="profile-text" sm>
                        <p>
                        Welcome here! My name is Jenni and I live just outside of Winnipeg, Manitoba, Canada. Although we experience very hot 
                        summers, when it gets cold here, it gets very cold! Sometimes our winters seem unbearably long. Just over 10 years ago
                        I began crocheting as a hobby, but eventually I realized that I could crochet items that will help me and others feel
                        warm and cozy through our long winters. In the last couple of years I've also picked up knitting to expand my skills 
                        and to give variety to what I can make. That has been so much fun!
                        </p>

                        <p>
                        The name Floranna comes from the names of two of my favourite crocheters: my Grandmother, Florabelle, and my Tante Anna.
                        Both of these women spent much of their time putting beautiful stitches together to create masterpieces of love for 
                        their families and friends. Their creativity inspires me.
                        </p>

                        <p>
                        I love how our Creator made us in his likeness, giving us a desire to make things and enjoy them. My goal in creating 
                        for others is to provide items that are practical to use, while at the same time having a beauty that one can enjoy and
                        appreciate while using it. Each stitch is made with intention and care. 
                        </p>

                        <p>
                        I hope that you can find something here that keeps you cozy!
                        </p>
                    </Col>
                    <Col id="profile-col" sm>
                        <div>
                            <img alt="Jenni's Profile" src="images/profile.png" id="profile"/>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}
